import React, { useContext } from 'react'
import CommonBack from '../../NewCheckOutPage/Components/CommonBack'
import { LanguageContext, VendorContext } from '../../../App'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ProductLoad = () => {
    const { language } = useContext(LanguageContext)
    const details = useContext(VendorContext)
    return (
        <div style={{ position: "relative" }}>
            <div className="product-details-backbutton">
                <CommonBack
                    variant={window.innerWidth < 991 ? "dark" : "white"}
                    clickButton={() => { }}
                />
            </div>
            <div style={{ position: "relative", backgroundColor: "#f3f3f3" }}>
                <div className="products-list-item shine-design" style={{ height: "400px" }}>
                </div>
            </div>
            <div className="details-container pt-2">
                <div className="product-outer-div">
                    <div className="product-inner-div">
                        <h3 className="product-name">
                            <div style={{ position: "relative", backgroundColor: "#f3f3f3", height: "32px", width: "100%" }}>
                                <div className="products-list-item shine-design" style={{ height: "32px", width: "100%" }}>
                                </div>
                            </div>
                        </h3>
                        <p className="product-category mt-3">

                            {navigator.share && (
                                <button className="sharewith">
                                    <i className="fa fa-arrow-up" aria-hidden="true"></i>{" "}
                                    {language === "ltr" ? "Share this page" : "شارك هذا الرابط"}
                                </button>
                            )}
                        </p>

                    </div>
                </div>
            </div>
            <React.Fragment>
                <div className="details-container pt-2">
                    <div className="product-outer-div">
                        <div className="product-inner-div">
                            <div className="product-price-div">
                                <p className="product-name">
                                    <div style={{ position: "relative", backgroundColor: "#f3f3f3", height: "24px", width: "80px" }}>
                                        <div className="products-list-item shine-design" style={{ height: "24px", width: "80px" }}>
                                        </div>
                                    </div>
                                </p>
                                <p className="product-price">
                                    <span>
                                        <div style={{ position: "relative", backgroundColor: "#f3f3f3", height: "24px", width: "80px" }}>
                                            <div className="products-list-item shine-design" style={{ height: "24px", width: "80px" }}>
                                            </div>
                                        </div>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default ProductLoad