import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  AreaContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import { API_URL } from "../../services/constants";
import ProductCarousel from "./components/ProductCarousel";
import ProductDetails from "./components/ProductDetails";
import SnapPixel from "react-snapchat-pixel";
import ReactPixel from 'react-facebook-pixel';
import sha256 from "sha256";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";
import ProductLoad from "./components/ProductLoad";
import { getProductDetails } from "../APIS/productApi";
import { viewProduct } from "../APIS/facebookApi";

function ProductPage() {
  const [product, setProduct] = useState({});
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { id } = useParams();
  const vendorSlug = useContext(VendorSlugContext);
  const [loading, setLoading] = useState(true);
  const { areaDetails } = useContext(AreaContext);
  const [addedVariaton, setAddedVariation] = useState([]);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
    if (details?.vendor?.fb_pixel_code && details?.vendor?.fb_pixel_code != "")
      ReactPixel.pageView();
  }, []);

  const handleProductDetails = async () => {
    try {
      const response = await getProductDetails({
        product_id: id,
        vendorSlug: vendorSlug,
        vendors_id: details?.vendor?.vendors_id,
        area_id: areaDetails?.area_id
      })
      if (response?.status) {
        setLoading((loading) => false);
        setProduct((prev) => response);
        if (details?.vendor?.fb_pixel_code && details?.vendor?.fb_pixel_code != "") {
          ReactPixel.trackCustom("ViewProduct", {
            content_name: response.data.name,
            content_category: response.data.category_name,
            content_type: "product",
          });
          if (vendorSlug == "mijana-restaurant-and-café") {
            await viewProduct({
              fb_pixel_code: details?.vendor?.fb_pixel_code,
              fb_access_token: "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
              support_mail: details?.vendor?.support_mail,
              product: {
                name: response.data.name,
                category_name: response.data.category_name
              }
            })
          }
          if (details?.vendor?.fb_access_token &&
            details?.vendor?.fb_access_token != "") {
            await viewProduct({
              fb_pixel_code: details?.vendor?.fb_pixel_code,
              fb_access_token: details?.vendor?.fb_access_token,
              support_mail: details?.vendor?.support_mail,
              product: {
                name: response.data.name,
                category_name: response.data.category_name
              }
            })

          }

        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (details?.vendor?.vendors_id) {
      handleProductDetails()
    }
  }, [details?.vendor]);


  return (
    <>
      {product?.data ? (
        <div style={{ position: "relative" }}>
          <div className="product-details-backbutton">
            <CommonBack
              variant={window.innerWidth < 991 ? "dark" : "white"}
            />
          </div>
          <ProductCarousel
            product={product.data}
            addedVariaton={addedVariaton}
            setAddedVariation={setAddedVariation}
            productvariationPrice={product?.productvariationPrice}
          ></ProductCarousel>
          <ProductDetails
            loading={loading}
            addon={product.addons}
            productvariation={product?.productvariation}
            productvariationPrice={product?.productvariationPrice}
            product={product.data}
            addedVariaton={addedVariaton}
            setAddedVariation={setAddedVariation}
          ></ProductDetails>
        </div>
      )
        :
        <ProductLoad />
      }
    </>
  );
}

export default ProductPage;
