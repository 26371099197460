import React, { useContext } from "react";
import { CartContext, LanguageContext, VendorContext } from "../../../App";

function Footer({ text }) {
  const { cart } = useContext(CartContext);
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  return (
    <>
      {
        <footer
          className={`${details?.vendor?.slug_type == 2 ? "" : "pt-3"
            } footer ${text} ${cart?.cartCount ? "footer-cart" : ""}`}
          style={{ minHeight: 230 }}

        >
          <a
            href="http://payzah.com/"
            style={{
              width: 220,
              height: 132,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-evenly",
                marginBottom: 10,
              }}
            >
              <img
                style={{ height: 125, width: "auto" }}
                src={"pictures/Footer Logo 1.png"}
              ></img>
            </div>
          </a>
        </footer>
      }
    </>
  );
}

export default Footer;
